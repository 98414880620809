import NewsletterCtaRow from 'components/newsletter-cta-row'
import SearchArticles from 'components/search-articles'
import { graphql, Link } from 'gatsby'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Layout from '../../layouts'

const slickSettings = {
    centerMode: true,
    centerPadding: '32px',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    draggable: true,
    variableWidth: true,
    dots: false,
    arrows: true,
    touchMove: true,
    infinite: true,
    responsive: [
        {
            breakpoint: 1120,
            settings: {
                touchMove: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                centerPadding: '24px',
                touchMove: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
    ],
}

class KennisbankPage extends Component {
    amountOfArticles = (slug) => {
        const articles = this.props.data.allContentfulArticle.edges
        let amount = 0

        articles.map((article) => {
            const catName = article.node.category.slug

            if (catName === slug) {
                amount += 1
            }
        })

        return amount
    }

    render() {
        const { edges: categories } = this.props.data.allCategories
        const { edges: mainArticles } = this.props.data.mainArticles
        const { edges: recentHrArticles } = this.props.data.recentHrArticles
        const { edges: recentNewsArticles } = this.props.data.recentNewsArticles
        const { edges: popularSalarisArticles } =
            this.props.data.popularSalarisArticles

        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>
                        Informatie om je salarisadministratie zelf te doen |
                        Employes
                    </title>
                    <meta
                        name="description"
                        content="Al onze kennis en informatie over het zelf doen van de salarisadminstratie voor je bedrijf."
                    />
                    <meta
                        itemprop="name"
                        content="Informatie om je salarisadministratie zelf te doen | Employes"
                    />
                    <meta
                        itemprop="description"
                        content="Al onze kennis en informatie over het zelf doen van de salarisadminstratie voor je bedrijf."
                    />
                    <meta itemprop="image" content="/static/meta-img.png" />
                </Helmet>

                <main className="kennisbank animated">
                    <header className="header padding-xl">
                        {/* <div className="backdrop search"></div> */}

                        <div className="container-md">
                            <div className="grid yg center text-center align-middle">
                                <div className="col-12 header-text">
                                    <h2 className="eyebrow">Hulp</h2>
                                    <h1>Blog</h1>
                                    <p className="streamer center margin-m-bottom">
                                        Bekijk de laatste trends, tips en
                                        inzichten om jouw HR &
                                        salarisadministratie tot in de puntjes
                                        goed te regelen.
                                    </p>

                                    <SearchArticles />

                                    <ul
                                        className="categories margin-m-top"
                                        id="categories"
                                    >
                                        <li
                                            data-target="alle artikelen"
                                            className="category-item margin-xxs-left margin-xs-right active"
                                            key="-"
                                        >
                                            <Link to="/blog/" title="Blog">
                                                <h6>Alle artikelen</h6>
                                            </Link>
                                        </li>
                                        {categories.map((category, idx) => (
                                            <Link
                                                to={
                                                    '/blog/' +
                                                    category.node.slug +
                                                    '/'
                                                }
                                                title={category.node.title}
                                            >
                                                <li
                                                    data-target={
                                                        category.node.title
                                                    }
                                                    className={
                                                        'category-item margin-xxs-left margin-xs-right'
                                                    }
                                                    key={idx}
                                                >
                                                    <h6>
                                                        {category.node.title}
                                                    </h6>
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="articles padding-l-bottom">
                        <div className="backdrop"></div>

                        <div className="container-md">
                            <div className="grid" ref="articles">
                                <div className="col-6">
                                    <Link
                                        to={
                                            '/' +
                                            mainArticles[0].node.slug +
                                            '/'
                                        }
                                        title={mainArticles[0].node.title}
                                    >
                                        <div className="articles-card articles-card-main no-margin">
                                            <img
                                                src={
                                                    mainArticles[0].node
                                                        .heroImage.file.url
                                                }
                                                alt="wat is salarisadministratie"
                                            />
                                            <div className="articles-card-content">
                                                <h4>
                                                    {mainArticles[0].node.title}{' '}
                                                </h4>
                                                <h2 className="eyebrow">
                                                    {
                                                        mainArticles[0].node
                                                            .category.title
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6">
                                    {mainArticles
                                        .slice(1)
                                        .map((article, idx) => (
                                            <Link
                                                to={
                                                    '/' +
                                                    article.node.slug +
                                                    '/'
                                                }
                                                title={article.node.title}
                                                key={idx}
                                            >
                                                <div className="articles-card articles-card-horizontal margin-s-bottom">
                                                    <img
                                                        src={
                                                            article.node
                                                                .heroImage.file
                                                                .url
                                                        }
                                                        alt={article.node.title}
                                                    />
                                                    <div className="articles-card-content">
                                                        <h5>
                                                            {article.node.title}
                                                        </h5>
                                                        <h2 className="eyebrow">
                                                            {
                                                                article.node
                                                                    .category
                                                                    .title
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="articles padding-xxl-bottom padding-xl-top">
                        <div className="container-md relative">
                            <div className="grid yg between margin-m-bottom">
                                <div className="col-12">
                                    <h2 className="eyebrow">
                                        {' '}
                                        Handige tips & tricks
                                    </h2>
                                    <h2>Salarisadministratie</h2>
                                    <p>
                                        Salarisadministratie is écht niet saai
                                        of moeilijk. Wij helpen je op weg met
                                        life saving tips.{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-lg no-pad slick-slider-container">
                            <h3 className="eyebrow center margin-xs-bottom">
                                Populaire artikelen
                            </h3>
                            <div className="grid yg">
                                <Slider {...slickSettings}>
                                    {popularSalarisArticles.map(
                                        (article, idx) => (
                                            <div className="col-4 content-box">
                                                <Link
                                                    to={
                                                        '/' +
                                                        article.node.slug +
                                                        '/'
                                                    }
                                                    title={article.node.title}
                                                    key={idx}
                                                >
                                                    <div className="articles-card no-margin">
                                                        <img
                                                            src={
                                                                article.node
                                                                    .heroImage
                                                                    .file.url
                                                            }
                                                            alt={
                                                                article.node
                                                                    .title
                                                            }
                                                        />
                                                        <div className="articles-card-content">
                                                            <h5
                                                                className="streamer"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: article
                                                                        .node
                                                                        .title,
                                                                }}
                                                            ></h5>
                                                            <h2 className="eyebrow">
                                                                {
                                                                    article.node
                                                                        .category
                                                                        .title
                                                                }
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    )}
                                </Slider>
                            </div>
                        </div>
                    </section>

                    <section className="articles hr padding-xxl">
                        <div className="container-md relative">
                            <div className="grid yg between margin-m-bottom">
                                <div className="col-12">
                                    <h2 className="eyebrow">
                                        Actuele trends en inzichten
                                    </h2>
                                    <h2>HR</h2>
                                    <p>
                                        De wereld van HR is divers en altijd in
                                        beweging. Check de laatste trends en
                                        ontwikkelingen.{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-lg no-pad slick-slider-container">
                            <h3 className="eyebrow center margin-xs-bottom">
                                Recente artikelen
                            </h3>
                            <div className="grid yg">
                                <Slider {...slickSettings}>
                                    {recentHrArticles.map((article, idx) => (
                                        <div className="col-4 content-box">
                                            <Link
                                                to={
                                                    '/' +
                                                    article.node.slug +
                                                    '/'
                                                }
                                                title={article.node.title}
                                                key={idx}
                                            >
                                                <div className="articles-card no-margin">
                                                    <img
                                                        src={
                                                            article.node
                                                                .heroImage.file
                                                                .url
                                                        }
                                                        alt={article.node.title}
                                                    />
                                                    <div className="articles-card-content">
                                                        <h5
                                                            className="streamer"
                                                            dangerouslySetInnerHTML={{
                                                                __html: article
                                                                    .node.title,
                                                            }}
                                                        ></h5>
                                                        <h2 className="eyebrow">
                                                            {
                                                                article.node
                                                                    .category
                                                                    .title
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </section>

                    <section className="articles padding-xxl">
                        <div className="container-md relative">
                            <div className="grid yg between margin-m-bottom">
                                <div className="col-12">
                                    <h2 className="eyebrow">Stay tuned</h2>
                                    <h2>Nieuws</h2>
                                    <p>
                                        Blijf op de hoogte van het laatste
                                        nieuws op het gebied van HR &
                                        salarisadministratie!{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-lg no-pad slick-slider-container">
                            <h3 className="eyebrow center margin-xs-bottom">
                                Recente artikelen
                            </h3>
                            <div className="grid yg">
                                <Slider {...slickSettings}>
                                    {recentNewsArticles.map((article, idx) => (
                                        <div className="col-4 content-box">
                                            <Link
                                                to={
                                                    '/' +
                                                    article.node.slug +
                                                    '/'
                                                }
                                                title={article.node.title}
                                                key={idx}
                                            >
                                                <div className="articles-card no-margin">
                                                    <img
                                                        src={
                                                            article.node
                                                                .heroImage.file
                                                                .url
                                                        }
                                                        alt={article.node.title}
                                                    />
                                                    <div className="articles-card-content">
                                                        <h5
                                                            className="streamer"
                                                            dangerouslySetInnerHTML={{
                                                                __html: article
                                                                    .node.title,
                                                            }}
                                                        ></h5>
                                                        <h2 className="eyebrow">
                                                            {
                                                                article.node
                                                                    .category
                                                                    .title
                                                            }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </section>

                    <div className="padding-l-bottom">
                        <NewsletterCtaRow />
                    </div>

                    <div className="container-md relative">
                        <div className="grid yg margin-l-bottom">
                            <div className="col-12">
                                <p className="seo-link">
                                    Tags:&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salarisadministratie-uitbesteden/"
                                    >
                                        Salarisadministratie uitbesteden
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salarispakket/"
                                    >
                                        Salarispakket
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salaris-software/"
                                    >
                                        Salaris-software
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/personeelsadministratie/"
                                    >
                                        Personeelsadministratie
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salarisadministratie-zelf-doen/"
                                    >
                                        Salarisadministratie-zelf-doen
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salarisverwerking/"
                                    >
                                        Salarisverwerking
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/loon-software/"
                                    >
                                        Loon-software
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/loonadministratie/"
                                    >
                                        Loonadministratie
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/loonstrook-maken/"
                                    >
                                        Loonstrook maken
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/salarisadministratiekantoor/"
                                    >
                                        Salarisadministratiekantoor
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/loonadministrateur/"
                                    >
                                       Loonadministrateur
                                    </Link>
                                    ,&nbsp;
                                    <br />
                                    <Link className="seo-link"
                                          to="/verloning/"
                                    >
                                        Verloning
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/loonpakket/"
                                    >
                                      Loonpakket
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                    className="seo-link"
                                    to="/loonadministratie-uitbesteden/"

                                    >
                                     Loonadministratie uitbesteden
                                  </Link>
                                  ,&nbsp;
                                  <Link
                                  className="seo-link"
                                  to= "/salarisprogramma/"

                                   >
                                    Salarisprogramma
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        className="seo-link"
                                        to="/declaratiesoftware/"
                                    >
                                        Declaratiesoftware
                                    </Link>
                                    ,&nbsp;
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*

        <div className="knowledgebase-blocks padding-xxl-bottom">
          <div className="container-md">
            <div className="grid">

              {edges.map((category, idx) => (
                <div className="col-8" key={idx}>
                  <Link to={`/hulp/kennisbank/${category.node.slug}/`} className="knowledgebase-blocks-block" >
                    <img src={shapes} alt="Shapes" />
                    <div className="content">
                      <h6 className="eyebrow">Bekijk alle artikelen:</h6>
                      <h6 className="eyebrow">{this.amountOfArticles(category.node.slug)} artikelen</h6>
                      <h3 className="margin-xs-bottom">{ category.node.title }</h3>
                      <p className="margin-s-bottom">{ category.node.shortDescription.shortDescription }</p>
                      <div className="btn secondary" >Lees meer <span className="arrow right"/></div>
                    </div>
                  </Link>
                </div>
              ))}

            </div>
          </div>
        </div>
*/}
                    {/*<ContactCtaRow className="margin-xl-bottom"/>*/}
                </main>
            </Layout>
        )
    }
}

export default KennisbankPage

export const kennisbankQuery = graphql`
    query allArticles {
        allCategories: allContentfulCategory(
            limit: 100
            filter: { node_locale: { eq: "nl" } }
            sort: { fields: [order], order: ASC }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    shortDescription {
                        shortDescription
                    }
                }
            }
        }

        mainArticles: allContentfulArticle(
            limit: 4
            sort: { fields: [published], order: DESC }
            filter: {
                node_locale: { eq: "nl" }
                hideInBlog: { eq: false }
            }
        ) {
            edges {
                node {
                    id
                    createdAt
                    published
                    heroImage {
                        id
                        file {
                            url
                        }
                    }
                    category {
                        id
                        slug
                        title
                    }
                    slug
                    title
                }
            }
        }

        popularSalarisArticles: allContentfulArticle(
            limit: 5
            sort: { fields: [published], order: DESC }
            filter: {
                node_locale: { eq: "nl" }
                category: { slug: { eq: "salarisadministratie" } }
                onMainpage: { eq: true }
                hideInBlog: { eq: false }
            }
        ) {
            edges {
                node {
                    id
                    createdAt
                    published
                    heroImage {
                        id
                        file {
                            url
                        }
                    }
                    category {
                        id
                        slug
                        title
                    }
                    slug
                    title
                }
            }
        }

        recentHrArticles: allContentfulArticle(
            limit: 5
            sort: { fields: [published], order: DESC }
            filter: {
                node_locale: { eq: "nl" }
                hideInBlog: { eq: false }
                category: { slug: { eq: "hr" } }
            }
        ) {
            edges {
                node {
                    id
                    createdAt
                    published
                    heroImage {
                        id
                        file {
                            url
                        }
                    }
                    category {
                        id
                        slug
                        title
                    }
                    slug
                    title
                }
            }
        }

        recentNewsArticles: allContentfulArticle(
            limit: 5
            sort: { fields: [published], order: DESC }
            filter: {
                node_locale: { eq: "nl" }
                hideInBlog: { eq: false }
                category: { slug: { eq: "nieuws" } }
            }
        ) {
            edges {
                node {
                    id
                    createdAt
                    published
                    heroImage {
                        id
                        file {
                            url
                        }
                    }
                    category {
                        id
                        slug
                        title
                    }
                    slug
                    title
                }
            }
        }
    }
`
